import type {
  PaymentMethodsListRequestType,
  PaymentMethodsListType,
  PaymentMethodsType,
} from '@redux/features/checkout/types/payment'

import getPaymentMethodsRequiredFields from '@utils/payment/getPymentMethodsRequiredFields'

export default (response: PaymentMethodsListRequestType) =>
  response.reduce((acc, method) => {
    ;(method as PaymentMethodsType & { requiredFields: string[] }).requiredFields = getPaymentMethodsRequiredFields(
      method.id
    )
    acc[method.id] = method as PaymentMethodsType
    return acc
  }, {} as PaymentMethodsListType)
