import { css } from 'styled-components'
import { keyframes } from 'styled-components'

export const shimmer = keyframes`
  0% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
`

export const SkeletonLightStyles = css`
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06));
  background-size: 400% 100%;
`

export const SkeletonDarkStyles = css`
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  background: linear-gradient(90deg, rgba(80, 83, 90, 0.16) 25%, rgba(144, 147, 157, 0.26) 37%, rgba(80, 83, 90, 0.16));
  background-size: 400% 100%;
`
