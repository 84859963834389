import getConfig from 'next/config'

const getAppConfig = () => {
  if (process.env.APP_CONFIG) {
    return process.env.APP_CONFIG
  }

  return getConfig()
}

export default getAppConfig
