import { usePathname } from 'next/navigation'

import { getVersionFromPath } from '@utils/string/url'

export const useVersionPage = () => {
  const pathname = usePathname()

  return getVersionFromPath(pathname)
}

export default useVersionPage
