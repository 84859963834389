import { urlVersionPrefix, urlVersionPrefixV2 } from '@constants/api/api'

export default {
  // /api/v2/user/check
  checkEmail: `${urlVersionPrefixV2}user/check`,

  // /api/v1/user/login
  login: `${urlVersionPrefix}user/login`,

  // /api/v1/user/login/code
  loginByCode: `${urlVersionPrefix}user/login/code`,

  // /api/v2/user/logout
  logout: `${urlVersionPrefixV2}user/logout`,

  // /api/v1/user/register
  register: `${urlVersionPrefix}user/register`,

  // /api/v1/user/reset
  reset: `${urlVersionPrefix}user/reset`,
}
