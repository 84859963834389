import type { FieldData } from 'rc-field-form/es/interface'

import { authorizationEndpoints, fixedPostLoginByCodeCacheKey } from '@redux/features/authorization/api'
import {
  saveEmail,
  setCurrentStep,
  setIsOpenAuthorizationPopup,
} from '@redux/features/authorization/authorization.slice'
import type { LoginByCodeType } from '@redux/features/authorization/types/login'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { ResponseErrorType } from '@Types/common/responseError'
import { AuthorizationStep } from '@components/authorization/constant/steps'
import { LoginByCodeFieldsName } from '@utils/forms/authorization/loginByCode'

export default createAsyncThunk<
  void,
  { loginForm: LoginByCodeType; setErrors: (fields: FieldData[]) => void },
  { state: RootState }
>('authorization/loginByCode', ({ loginForm, setErrors }, { dispatch }) => {
  void dispatch(
    authorizationEndpoints.postLoginByCode.initiate(loginForm, { fixedCacheKey: fixedPostLoginByCodeCacheKey })
  )
    .unwrap()
    .then(() => {
      dispatch(setIsOpenAuthorizationPopup(false))
      dispatch(setCurrentStep(AuthorizationStep.LOGIN))
      dispatch(saveEmail(''))
    })
    .catch((error: ResponseErrorType) => {
      setErrors([{ errors: [error.data.message], name: LoginByCodeFieldsName.Code }])
    })
})
