import type { UserType } from '@redux/features/authorization/types/login'

import authStorage from '@storageServices/storageEntities/auth'

export default (response: UserType) => {
  response.auth_token &&
    authStorage.setValue({
      roles: response.current_user?.roles,
      userId: response.current_user?.uid ? Number(response.current_user.uid) : undefined,
      'X-Auth-Token': response.auth_token,
    })
  return response
}
