import { api } from '@redux/api/api'
import type { BannerBlockInfoType } from '@redux/features/homePage/types/bannerBlockInfo'
import type { HomePageEntityType } from '@redux/features/homePage/types/blocksHomePage'
import type { ParagraphType } from '@redux/features/homePage/types/paragraph'
import type { PopularRoutesType } from '@redux/features/homePage/types/popularRoutes'

import type { ExtendBlocksType } from '@Types/blocks/blocks'
import type { PaymentMethodType } from '@Types/blocks/paymentMethods'

import homePageUrls from '../constants/urls'

const homePageApi = api.injectEndpoints({
  endpoints: build => ({
    getBannerBlockInfo: build.query<BannerBlockInfoType, number | string>({
      keepUnusedDataFor: 600,
      query: node => ({ url: homePageUrls.bannerBlockInfo(node) }),
    }),
    getBlock: build.query<ExtendBlocksType, number>({
      keepUnusedDataFor: 600,
      query: blockId => ({ url: homePageUrls.contentBlock(blockId) }),
    }),
    getExhibitionPaymentMethods: build.query<PaymentMethodType[], string[]>({
      keepUnusedDataFor: 600,
      query: (paymentMethods?: string[]) => ({ url: homePageUrls.paymentMethods(paymentMethods) }),
    }),
    getHomePageEntity: build.query<HomePageEntityType, void>({
      keepUnusedDataFor: 600,
      query: () => ({ url: homePageUrls.homePage }),
    }),
    getParagraph: build.query<ParagraphType, number>({
      keepUnusedDataFor: 600,
      query: paragraphId => ({ url: homePageUrls.paragraph(paragraphId) }),
    }),
    getPopularRoutesMenuItems: build.query<PopularRoutesType, string>({
      keepUnusedDataFor: 600,
      query: (menuName: string) => ({ url: homePageUrls.menuItems(menuName) }),
    }),
  }),
})
export const {
  endpoints: homePageEndpoints,
  useGetBannerBlockInfoQuery,
  useGetBlockQuery,
  useGetExhibitionPaymentMethodsQuery,
  useGetHomePageEntityQuery,
  useGetParagraphQuery,
  useGetPopularRoutesMenuItemsQuery,
} = homePageApi

export const {
  getBannerBlockInfo,
  getBlock,
  getExhibitionPaymentMethods,
  getHomePageEntity,
  getParagraph,
  getPopularRoutesMenuItems,
} = homePageEndpoints
