import isClient from '@components/common/isClient'
import deleteByPath from '@utils/object/deleteByPath'
import mergeData from '@utils/object/mergeObjects'

type StorageType = 'locale' | 'session'
type Params = { storageKey: string; storageType?: StorageType }

class StorageHandler<StoredDataType> {
  private storage: null | Storage
  readonly storageKey: string
  readonly storageType: StorageType

  constructor({ storageKey, storageType = 'locale' }: Params) {
    this.storageKey = storageKey
    this.storageType = storageType
    this.storage = isClient ? (storageType === 'session' ? sessionStorage : localStorage) : null
  }

  //Merge with exist storage
  addValues(newValues: Partial<StoredDataType>) {
    const storageData = this.getValue()
    const newStorageData = mergeData(storageData || {}, newValues)
    this.setValue(newStorageData as StoredDataType)
  }

  //Remove all object
  clear() {
    this.storage?.removeItem(this.storageKey)
  }

  getValue() {
    const storedData = this.storage?.getItem(this.storageKey)

    if (storedData) {
      try {
        return JSON.parse(storedData) as StoredDataType
      } catch (error) {
        console.error('Failed to parse stored data', error)
      }
    }
  }

  getValueByKey<K extends keyof StoredDataType>(key: K): StoredDataType[K] | undefined {
    const storageData = this.getValue()
    return storageData?.[key]
  }

  //'storage.value.color' remove only color key, 'storage.value.color[2]' remove 3rd item in colors array
  removeByPath(path: string) {
    const storageData = this.getValue()
    if (storageData) {
      storageData && deleteByPath(storageData, path)
      this.setValue(storageData)
    }
  }

  //Replace all object
  setValue(value: StoredDataType) {
    this.storage?.setItem(this.storageKey, JSON.stringify(value))
  }
}

export default StorageHandler
