export enum TrainFilters {
  ARRIVAL_STATION = 'arrivalStation',
  ARRIVAL_TIME = 'arrivalTime',
  DEPARTURE_STATION = 'departureStation',
  DEPARTURE_TIME = 'departureTime',
  PRICE = 'price',
  TRAVEL_CLASS = 'travelClass',
  TRAVEL_TIME = 'travelTime',
}

export enum TimeOfDayName {
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
  MORNING = 'morning',
  NIGHT = 'night',
}

export const timeRange = {
  [TimeOfDayName.AFTERNOON]: {
    hourFormat12: {
      max: 5,
      min: 12,
    },
    hourFormat24: {
      max: 18,
      min: 12,
    },
  },
  [TimeOfDayName.EVENING]: {
    hourFormat12: {
      max: 10,
      min: 6,
    },
    hourFormat24: {
      max: 23,
      min: 18,
    },
  },
  [TimeOfDayName.MORNING]: {
    hourFormat12: {
      max: 11,
      min: 5,
    },
    hourFormat24: {
      max: 12,
      min: 5,
    },
  },
  [TimeOfDayName.NIGHT]: {
    hourFormat12: {
      max: 4,
      min: 11,
    },
    hourFormat24: {
      max: 5,
      min: 23,
    },
  },
}
