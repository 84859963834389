export enum PaymentStatuses {
  FAILED = 'failed',
  PENDING = 'pending',
  SUCCESS = 'success',
}

export enum PaymentActions {
  AUTOSUBMIT_FORM = 'autosubmit_form',
  AUTOSUBMIT_FORM_INTO_IFRAME = 'autosubmit_form_into_iframe',
  PASS_GET_PARAMETERS = 'pass_get_parameters',
  RECEIVE_ADDITIONAL_DATA = 'receive_additional_data',
  REDIRECT = 'redirect',
}
