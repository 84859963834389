import type { IRoute } from '@Types/routes/route'

const getAllCoachClasses = (initTrains: IRoute['trains']) => {
  return Object.values(initTrains).flatMap(item => item.coach_classes)
}

const getAllCheapestCoachClasses = (initTrains: IRoute['trains']) => {
  return Object.values(initTrains).flatMap(item => item.cheapest_coach_class)
}

export const getAllCheapestPrices = (initTrains: IRoute['trains']) => {
  const fares = getAllCheapestCoachClasses(initTrains).flatMap(item => item?.cheapest_fare)
  const prices: number[] = []
  fares.flatMap(item => item?.price && prices.push(Math.trunc(item.price.number)))
  return prices
}

export const getAllPrices = (initTrains: IRoute['trains']) => {
  const fares = getAllCoachClasses(initTrains).flatMap(item => item.fares)
  const prices: number[] = []
  fares.flatMap(item => item.price && prices.push(Math.trunc(item.price.number)))
  return prices
}
