import { getParamPaymentMethods } from '@redux/features/homePage/utils/getParamPaymentMethods'

import { urlVersionPrefix } from '@constants/api/api'

export default {
  // /api/v1/banner-block-info/{node}
  bannerBlockInfo: (node: number | string) => `${urlVersionPrefix}banner-block-info/${node}`,

  // /api/v1/admin/content/block/{blockId}
  contentBlock: (blockId: number) => `${urlVersionPrefix}admin/content/block/${blockId}`,

  // /api/v1/pages/front
  homePage: `${urlVersionPrefix}pages/front`,

  // /api/v1/payment-methods
  menuItems: (menuName: string) => `${urlVersionPrefix}menu-items/${menuName}`,

  // /api/v1/entity/paragraph/{paragraphId}
  paragraph: (paragraphId: number) => `${urlVersionPrefix}entity/paragraph/${paragraphId}`,

  // /api/v1/payment-methods
  paymentMethods: (paymentMethods?: string[]) =>
    `${urlVersionPrefix}payment-methods?${getParamPaymentMethods(paymentMethods)}`,
}
