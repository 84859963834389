import { HYDRATE, createWrapper } from 'next-redux-wrapper'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist'
import { type Persistor } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

import { api } from '@redux/api/api'
import { rtkQueryErrorLogger } from '@redux/errorHandling'
import ai from '@redux/features/ai/ai.slice'
import analytics from '@redux/features/analytics/analytics.slice'
import authorization from '@redux/features/authorization/authorization.slice'
import cart, { cartSlice } from '@redux/features/cart/cart.slice'
import checkout, { checkoutSlice } from '@redux/features/checkout/checkout.slice'
import commonApp from '@redux/features/commonApp/commonApp.slice'
import modals, { modalsSlice } from '@redux/features/modals/modals.slice'
import payment from '@redux/features/payment/payment.slice'
import preferences from '@redux/features/preferences/preferences.slice'
import recommendedRoutes from '@redux/features/recommendedRoutes/recommendedRoutes.slice'
import search, { searchSlice } from '@redux/features/search/search.slice'
import { tildaApi } from '@redux/features/tilda/tilda.api'
import trainFilters from '@redux/features/trainFilters/trainFilters.slice'
import user from '@redux/features/user/user.slice'
import type { Action, AnyAction, ThunkAction } from '@reduxjs/toolkit'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

import isClient from '@components/common/isClient'
import isProd from '@constants/env/isProd'
import routes from '@constants/routes/routes'

export let isHydrated = false

export const rootReducer = combineReducers({
  ai,
  analytics,
  [api.reducerPath]: api.reducer,
  authorization,
  cart,
  checkout,
  commonApp,
  modals,
  payment,
  preferences,
  recommendedRoutes,
  search,
  [tildaApi.reducerPath]: tildaApi.reducer,
  trainFilters,
  user,
})

const rootReducerWithHydrateAction = (state: ReturnType<typeof rootReducer>, action: AnyAction) => {
  if (action.type === HYDRATE && !isHydrated) {
    isHydrated = true //for fix multiple HYDRATE action problem
    return {
      ...state,
      ...action.payload,
      commonApp: state.commonApp,
      user: state.user,
    } as RootState
  }

  return rootReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage: storageSession,
  version: 1,
  whitelist: [checkoutSlice.name, cartSlice.name, searchSlice.name, modalsSlice.name],
}

const persistedReducer = persistReducer(persistConfig, rootReducerWithHydrateAction as typeof rootReducer)

const makeStore = () => {
  const store = configureStore({
    devTools: !isProd,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(api.middleware, tildaApi.middleware, rtkQueryErrorLogger),
    reducer: persistedReducer,
  })

  type StoreType = typeof store & { __persistor: Persistor }

  const typedStore = store as StoreType
  typedStore.__persistor = persistStore(store)

  if (isClient && !window.location.pathname.includes(routes.checkout)) {
    typedStore.__persistor.pause()
    void typedStore.__persistor.purge()
  }

  return typedStore
}

export type AppStore = ReturnType<typeof makeStore>
export type AppDispatch = AppStore['dispatch']
export type RootState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export const wrapper = createWrapper<AppStore>(makeStore)
