export enum AuthorizationStep {
  CREATE_PASSWORD,
  LOGIN,
  LOGIN_BY_CODE,
  LOGIN_BY_CODE_EXTENDED,
  LOGIN_EXTENDED,
  CHECK_EMAIL_NOTIFICATION,
  REGISTRATION,
  REGISTRATION_CHECK_EMAIL,
  REGISTRATION_CHECK_EMAIL_EXTENDED,
  RESET_PASSWORD,
  SUPPORT_FORM,
}
