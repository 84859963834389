import type { TrainFilters } from '@redux/features/trainFilters/constants/trainFilters.constants'
import type { StationFilterType } from '@redux/features/trainFilters/types/trainFiltersState'

import type { IRoute } from '@Types/routes/route'

import getStationNames from './getStationNames'

export const updateStationFilterState = (
  trains: IRoute['trains'],
  name: TrainFilters.ARRIVAL_STATION | TrainFilters.DEPARTURE_STATION,
  filteredTrains?: IRoute['trains'],
  prevFilters?: StationFilterType[]
): StationFilterType[] => {
  const stationNames = Array.from(getStationNames(trains, name))

  return stationNames.map(stationName => {
    const value = prevFilters?.find(item => item.name === stationName)?.value ?? 0

    return {
      disabled: filteredTrains ? getIsDisabled(stationName, filteredTrains, name) && !value : false,
      name: stationName,
      recommended: prevFilters?.find(item => item.name === stationName)?.recommended ?? false,
      value,
    }
  })
}

const getIsDisabled = (
  stationName: string,
  filteredTrains: IRoute['trains'],
  filterName: TrainFilters.ARRIVAL_STATION | TrainFilters.DEPARTURE_STATION
) => {
  return !getStationNames(filteredTrains, filterName).has(stationName)
}
