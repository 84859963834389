import { PaymentFormFields } from '@constants/payment/paymentFormFields'

export const basicRequiredFields = [PaymentFormFields.EMAIL, PaymentFormFields.PHONE_NUMBER]

export const creditCardRequiredFields = [
  PaymentFormFields.CARD_NUMBER,
  PaymentFormFields.CARD_TYPE,
  PaymentFormFields.NAME_ON_CARD,
  PaymentFormFields.CARD_EXPIRATION_DATE,
  PaymentFormFields.SECRET_CODE,
  ...basicRequiredFields,
]

export const appleGooglePayRequiredFields = [PaymentFormFields.NONCE, ...basicRequiredFields]
