import type { EffectsType } from '../../../types/themes/effects'
import type { TypographyType } from '../../../types/themes/typography'
import convertPropertiesToCSS from '../convertPropertiesToCSS'
import convertShadowToCSS from '../convertShadowToCSS'

export interface JsonObject {
  [key: string]: any //TODO remove any
}

enum ThemeProperties {
  COLOR = 'color',
  SHADOW = 'shadow',
  typography = 'typography',
}

const replaceValue = (obj: JsonObject, handler: (value: any) => string, typeToReplace: ThemeProperties) => {
  const newObj = structuredClone(obj)

  for (const key in newObj) {
    if (typeof newObj[key] === 'object' && newObj[key] !== null) {
      if ('$value' in newObj[key] && newObj[key]['$type'] === typeToReplace) {
        newObj[key].$value = handler(newObj[key].$value)
      }
      newObj[key] = replaceValue(newObj[key] as JsonObject, handler, typeToReplace)
    }
  }
  return newObj
}

export const themePropertyConverters = {
  color: (obj: JsonObject) => replaceValue(obj, (value: string) => value, ThemeProperties.COLOR),
  effects: (obj: JsonObject) => replaceValue(obj, convertShadowToCSS, ThemeProperties.SHADOW) as EffectsType,
  typography: (obj: JsonObject) =>
    replaceValue(obj, convertPropertiesToCSS, ThemeProperties.typography) as TypographyType,
}
