import type { FieldData } from 'rc-field-form/es/interface'

import { setCurrentStep } from '@redux/features/authorization/authorization.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { ResponseErrorType } from '@Types/common/responseError'
import { AuthorizationStep } from '@components/authorization/constant/steps'
import type { RegistrationCheckEmailFormType } from '@utils/forms/authorization/registration'
import { RegistrationFieldsName } from '@utils/forms/authorization/registration'

import type { PostCheckEmailType } from '../../types/checkEmail'

export default createAsyncThunk<
  void,
  {
    data: RegistrationCheckEmailFormType
    postCheckEmail: PostCheckEmailType
    setErrors: (fields: FieldData[]) => void
  },
  { state: RootState }
>('authorization/registrationCheckEmail', ({ data, postCheckEmail, setErrors }, { dispatch }) => {
  postCheckEmail({ agree_policy: 1, email: data.email || '' })
    .unwrap()
    .then(response => {
      const { message, success } = response

      if (success) {
        dispatch(setCurrentStep(AuthorizationStep.LOGIN_EXTENDED))
      } else {
        setErrors([{ errors: [message], name: RegistrationFieldsName.Email }])
      }
    })
    .catch((error: ResponseErrorType) => {
      if (error.status === 404) {
        dispatch(setCurrentStep(AuthorizationStep.REGISTRATION))
      } else {
        setErrors([{ errors: [error.data.message], name: RegistrationFieldsName.Email }])
      }
    })
})
