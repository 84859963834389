import type { Theme } from '../../types/themes/theme'
import colors from '../commonTheme/colors'
import { commonTheme } from '../commonTheme/commonTheme'
import OrangeThemeToken from '../design-tokens/Tokens.orange.tokens.json'

const orangeTheme: Theme = {
  colors: { ...colors, ...OrangeThemeToken },
  ...commonTheme,
}

export default orangeTheme
