export enum TimetableFieldSort {
  DEPARTURE = 'departure_datetime',
  PRICE = 'cheapest_coach_class.cheapest_fare.price.number',
  SPEED = 'running_time',
}

export const timetableFieldSortMap = [TimetableFieldSort.DEPARTURE, TimetableFieldSort.SPEED, TimetableFieldSort.PRICE]

export enum OrderBySort {
  ASC = 'asc',
  DESC = 'desc',
}
