type GetLinkToTimetableType = {
  arrivalStationId: number
  departureDate: string
  depStationId: number
  passengers: number
}

export const aiSearchWidgetUtils = {
  get() {
    return (typeof document !== 'undefined' && document.getElementById('ai-search-widget')) || null
  },
  getDomain() {
    return this.get()?.getAttribute('data-domain')
  },
  getLinkToTimetable({ arrivalStationId, departureDate, depStationId, passengers }: GetLinkToTimetableType) {
    const href = this.getDomain() || window.location.href
    return `${href}?passengers[adults]=${passengers}&form-mode=basic-mode&no_redirect=0&legs[1][departure_station]=${depStationId}&legs[1][arrival_station]=${arrivalStationId}&legs[1][departure_date]=${departureDate}&ai=1`
  },
  getPlaceholder() {
    return this.get()?.getAttribute('data-placeholder')
  },
  getXKey() {
    return this.get()?.getAttribute('data-x-key')
  },
}
