import dayjs from 'dayjs'

import { TimeOfDayName, TrainFilters, timeRange } from '@redux/features/trainFilters/constants/trainFilters.constants'
import type { TimeOfDayType } from '@redux/features/trainFilters/types/trainFiltersState'

import type { IRoute } from '@Types/routes/route'

const timeOfDayOrder = [TimeOfDayName.MORNING, TimeOfDayName.AFTERNOON, TimeOfDayName.EVENING, TimeOfDayName.NIGHT]

type getTimeOfDayFiltersType = {
  filteredTrains: IRoute['trains']
  initTrains: IRoute['trains']
  name: TrainFilters.ARRIVAL_TIME | TrainFilters.DEPARTURE_TIME
  previousTimeSlots?: TimeOfDayType[]
}

export const getTimeOfDayFilters = ({
  filteredTrains,
  initTrains,
  name,
  previousTimeSlots,
}: getTimeOfDayFiltersType): TimeOfDayType[] => {
  const timeSlotsInitTrains = Object.entries(getCountTrainsInTimeSlots(initTrains, name))
  const timeSlotsFilteredTrains = getCountTrainsInTimeSlots(filteredTrains, name)

  timeSlotsInitTrains.forEach(([name, data]) => {
    if (!data.count) delete timeSlotsFilteredTrains[name as TimeOfDayName]
  })

  const timeSlotsList = Object.entries(timeSlotsFilteredTrains).map(([name, { count, description }]) => {
    return {
      count,
      description: description,
      disabled: !count,
      name: name as TimeOfDayName,
      value: 0,
    }
  })

  const timeSlotsListSorted = timeSlotsList.sort(
    (first, second) => timeOfDayOrder.indexOf(first.name) - timeOfDayOrder.indexOf(second.name)
  )

  return timeSlotsListSorted.map((slot, index) => {
    const prevSlot = previousTimeSlots?.[index]
    return {
      ...slot,
      disabled: Boolean(slot?.disabled && !prevSlot?.value),
      value: prevSlot?.value || 0,
    }
  })
}

const getCountTrainsInTimeSlots = (
  trains: IRoute['trains'],
  name: TrainFilters.ARRIVAL_TIME | TrainFilters.DEPARTURE_TIME
) => {
  const timeSlots = {
    [TimeOfDayName.AFTERNOON]: {
      count: 0,
      description: `(${timeRange[TimeOfDayName.AFTERNOON].hourFormat12.min} PM - ${
        timeRange[TimeOfDayName.AFTERNOON].hourFormat12.max
      } PM)`,
    },
    [TimeOfDayName.EVENING]: {
      count: 0,
      description: `(${timeRange[TimeOfDayName.EVENING].hourFormat12.min} PM - ${
        timeRange[TimeOfDayName.EVENING].hourFormat12.max
      } PM)`,
    },
    [TimeOfDayName.MORNING]: {
      count: 0,
      description: `(${timeRange[TimeOfDayName.MORNING].hourFormat12.min} AM - ${
        timeRange[TimeOfDayName.MORNING].hourFormat12.max
      } AM)`,
    },
    [TimeOfDayName.NIGHT]: {
      count: 0,
      description: `(${timeRange[TimeOfDayName.NIGHT].hourFormat12.min} PM - ${
        timeRange[TimeOfDayName.NIGHT].hourFormat12.max
      } AM)`,
    },
  }

  Object.values(trains).forEach(train => {
    const hour = getHour(name === TrainFilters.ARRIVAL_TIME ? train.arrival_datetime : train.departure_datetime)

    switch (true) {
      case isNaN(hour):
        return
      case hour >= timeRange[TimeOfDayName.MORNING].hourFormat24.min &&
        hour < timeRange[TimeOfDayName.MORNING].hourFormat24.max:
        timeSlots[TimeOfDayName.MORNING].count++
        break
      case hour >= timeRange[TimeOfDayName.AFTERNOON].hourFormat24.min &&
        hour < timeRange[TimeOfDayName.AFTERNOON].hourFormat24.max:
        timeSlots[TimeOfDayName.AFTERNOON].count++
        break
      case hour >= timeRange[TimeOfDayName.EVENING].hourFormat24.min &&
        hour < timeRange[TimeOfDayName.EVENING].hourFormat24.max:
        timeSlots[TimeOfDayName.EVENING].count++
        break
      case hour >= timeRange[TimeOfDayName.NIGHT].hourFormat24.min ||
        hour < timeRange[TimeOfDayName.NIGHT].hourFormat24.max:
        timeSlots[TimeOfDayName.NIGHT].count++
        break
    }
  })

  return timeSlots
}

export const checkTimeslot = (checkedTimes: TimeOfDayType[], hour: number) => {
  return checkedTimes.some(timeSlot => {
    switch (timeSlot.name) {
      case TimeOfDayName.MORNING:
        return (
          hour >= timeRange[TimeOfDayName.MORNING].hourFormat24.min &&
          hour < timeRange[TimeOfDayName.MORNING].hourFormat24.max
        )
      case TimeOfDayName.AFTERNOON:
        return (
          hour >= timeRange[TimeOfDayName.AFTERNOON].hourFormat24.min &&
          hour < timeRange[TimeOfDayName.AFTERNOON].hourFormat24.max
        )
      case TimeOfDayName.EVENING:
        return (
          hour >= timeRange[TimeOfDayName.EVENING].hourFormat24.min &&
          hour < timeRange[TimeOfDayName.EVENING].hourFormat24.max
        )
      case TimeOfDayName.NIGHT:
        return (
          hour >= timeRange[TimeOfDayName.NIGHT].hourFormat24.min ||
          hour < timeRange[TimeOfDayName.NIGHT].hourFormat24.max
        )
      default:
        return false
    }
  })
}

export const getHour = (time: string) => {
  const formattedTime = dayjs.parseZone(time)
  return formattedTime.hour()
}
