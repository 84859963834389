import { internalApiPrefixV3 } from '@constants/api/api'

export default {
  // /api/v3/internal/train/booking/{hash}/payment/apple-pay-session -- payment of basic bunking with a session
  bookingApplePaySession: (hash: string) => `${internalApiPrefixV3}/train/booking/${hash}/payment/apple-pay-session`,

  // /api/v3/internal/train/booking/{hash}/payment
  bookingPayment: (hash: string) => `${internalApiPrefixV3}/train/booking/${hash}/payment`,

  // /api/v3/internal/train/booking/{hash}/payment-method
  bookingPaymentMethods: (hash: string) => `${internalApiPrefixV3}/train/booking/${hash}/payment-method`,

  // /api/v3/internal/invoice/{invoice}/payment/apple-pay-session -- invoice payment (when clicking Try again on ty page)
  invoiceApplePaySession: (invoice: string) => `${internalApiPrefixV3}/invoice/${invoice}/payment/apple-pay-session`,

  // Needed when there's a modification to the order
  // /api/v3/internal/train/modification/{store_order}/payment/apple-pay-session -- payment for booking modification
  // orderModificationApplePaySession: (store_order: string) =>
  //   `${internalApiPrefixV3}/train/modification/${store_order}/payment/apple-pay-session`,
}
