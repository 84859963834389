const deleteByPath = (object: Record<string, unknown>, path: string) => {
  const keys = path.split('.')
  let currentObj = object

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i]
    if (!Object.prototype.hasOwnProperty.call(currentObj, key)) {
      return
    }

    currentObj = currentObj[key] as Record<string, unknown>
  }

  const lastKey = keys[keys.length - 1]

  if (Array.isArray(currentObj)) {
    currentObj.splice(Number(lastKey), 1)
  } else if (typeof currentObj === 'object') {
    delete currentObj[lastKey]
  }
}

export default deleteByPath
