import { api } from '@redux/api/api'
import type { AffiliateProgramDataType } from '@redux/features/search/types/affiliateProgram'
import type { ISearchConfiguration } from '@redux/features/search/types/searchState'
import type {
  SearchFormStationsRequestType,
  SearchFormStationsType,
  SearchStationsOptionType,
} from '@redux/features/search/types/searchStations'

import type { ISearchHistory } from '@Types/common/search'
import { additionalBaseUrl, urlVersionPrefix, urlVersionPrefixV2 } from '@constants/api/api'
import { XAPIUserKeyAdditional } from '@constants/api/tokens'

const searchApi = api.injectEndpoints({
  endpoints: build => ({
    getAffiliateProgram: build.query<AffiliateProgramDataType, void>({
      query: () => ({
        url: urlVersionPrefix + 'affiliate-program',
      }),
    }),
    getSearchConfiguration: build.query<ISearchConfiguration, void>({
      query: () => ({
        url: urlVersionPrefix + 'search/configuration',
      }),
    }),
    getSearchFormStation: build.query<SearchFormStationsType, { from: number | string; to: number | string }>({
      query: ({ from, to }) => ({
        url: urlVersionPrefix + `search-form-stations/${from}/${to}`,
      }),
      transformResponse: (data: SearchFormStationsRequestType) => {
        return Object.entries(data).reduce((acc, [key, value]) => {
          acc[key as keyof SearchFormStationsType] = { ...value, label: value.name, value: value.uuid }
          return acc
        }, {} as SearchFormStationsType)
      },
    }),
    getSearchHistory: build.query<ISearchHistory[] | undefined, void>({
      query: () => ({
        url: urlVersionPrefix + 'station/history',
      }),
      transformResponse: (response: ISearchHistory[]) => (response.length ? response : undefined),
    }),
    getStations: build.query<SearchStationsOptionType[], { name?: string; selectedStationId?: string }>({
      keepUnusedDataFor: 600,
      query: ({ name, selectedStationId }) => ({
        credentials: 'include',
        headers: { 'X-API-User-Key': XAPIUserKeyAdditional },
        params: {
          name: name?.toLowerCase(),
        },
        url: `${additionalBaseUrl}${urlVersionPrefixV2}station/search-option${
          selectedStationId ? `/${selectedStationId}` : ''
        }`,
      }),
    }),
  }),
})

export const {
  endpoints: searchEndpoints,
  useGetSearchConfigurationQuery,
  useGetSearchFormStationQuery,
  useGetSearchHistoryQuery,
  useGetStationsQuery,
} = searchApi
