import type { RecommendedRoutesSliceStateType } from '@redux/features/recommendedRoutes/types/recommendedRoutes'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: RecommendedRoutesSliceStateType = {
  isOpenLayovers: false,
}

export const recommendedRoutesSlice = createSlice({
  initialState,
  name: 'recommendedRoutes',
  reducers: {
    setIsOpenLayovers: (state, action: PayloadAction<boolean>) => {
      state.isOpenLayovers = action.payload
    },
  },
})

export const { setIsOpenLayovers } = recommendedRoutesSlice.actions

export default recommendedRoutesSlice.reducer
