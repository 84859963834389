//replace by lodash if bugs
const mergeData = <T>(target: T, source: T): T => {
  if (Array.isArray(target) && Array.isArray(source)) {
    return target.concat(source) as T
  }

  if (typeof target === 'object' && typeof source === 'object') {
    const mergedData = { ...target }

    for (const key in source) {
      if (mergedData && Object.prototype.hasOwnProperty.call(source, key)) {
        if (Object.prototype.hasOwnProperty.call(mergedData, key)) {
          mergedData[key] = mergeData(mergedData[key], source[key])
        } else {
          mergedData[key] = source[key]
        }
      }
    }

    return mergedData
  }

  return source
}

export default mergeData
