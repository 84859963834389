import dayjs from 'dayjs'

import type { ITrain } from '@Types/routes/train'

export default ({ arrivalDatetime, trains }: { arrivalDatetime?: string; trains: [] | Record<string, ITrain> }) => {
  if (!arrivalDatetime) return Array.isArray(trains) ? {} : trains
  const arrival = dayjs(arrivalDatetime).add(30, 'minute')

  const filteredTrain = Object.entries(trains).filter(([, train]) => {
    const departure = dayjs(train.departure_datetime)
    return departure.isAfter(arrival)
  })

  return filteredTrain.length ? Object.fromEntries(filteredTrain) : {}
}
