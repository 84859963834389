import type { ScriptProps } from 'next/dist/client/script'
import Script from 'next/script'
import type { FC } from 'react'

type Props = {
  scriptsList?: ScriptProps[]
}

const CustomScripts: FC<Props> = ({ scriptsList }) => {
  if (process.env.NODE_ENV !== 'production' || !scriptsList) return null

  return scriptsList.map(script => <Script {...script} key={script.src} />)
}

export default CustomScripts
