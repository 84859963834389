import { resetAccountFormId } from '@utils/forms/authorization/resetPassword'

export const registrationCheckEmailFormId = 'registration-check-email-form'
export const registrationFormId = 'registration-form'

export const titleSubmit = {
  [registrationCheckEmailFormId]: 'next',
  [registrationFormId]: 'signUp',
  [resetAccountFormId]: 'submit',
}

export enum RegistrationFieldsName {
  AgreePolicy = 'agree_policy',
  Captcha = 'captcha',
  Email = 'email',
  FirstName = 'first_name',
  FullName = 'full_name',
  LastName = 'last_name',
  Password = 'password',
  PasswordConfirm = 'password_confirm',
}

export type RegistrationFieldsKeys =
  | RegistrationFieldsName.Email
  | RegistrationFieldsName.FullName
  | RegistrationFieldsName.Password
  | RegistrationFieldsName.PasswordConfirm

export const registrationFieldsPlaceHolders: { [key in RegistrationFieldsKeys]: string } = {
  [RegistrationFieldsName.Email]: 'yourEmail',
  [RegistrationFieldsName.FullName]: 'yourName',
  [RegistrationFieldsName.Password]: 'password',
  [RegistrationFieldsName.PasswordConfirm]: 'confirmPassword',
}

export type RegistrationCheckEmailFormType = Partial<{
  [RegistrationFieldsName.AgreePolicy]: number
  [RegistrationFieldsName.Email]: string
}>

export type RegistrationFormType = Partial<{
  [RegistrationFieldsName.AgreePolicy]: number
  [RegistrationFieldsName.Captcha]: string
  [RegistrationFieldsName.Email]: string
  [RegistrationFieldsName.FullName]: string
  [RegistrationFieldsName.Password]: string
  [RegistrationFieldsName.PasswordConfirm]: string
}>
