import { api } from '@redux/api/api'
import { TagTypes } from '@redux/api/tagTypes'
import type { AccountResetResponseType } from '@redux/features/authorization/types/accountReset'
import type {
  CheckEmailType,
  LoginByCodeType,
  PostLoginPayloadType,
  ResponseCheckEmailType,
  UserType,
} from '@redux/features/authorization/types/login'
import type { ResponseRegistrationType } from '@redux/features/authorization/types/registartion'

import cleanUserDataStorage from '@utils/browserStorages/cleanUserDataStorage'
import type { RegistrationFormType } from '@utils/forms/authorization/registration'
import type { AccountPasswordFormType } from '@utils/forms/authorization/resetPassword'

import authorizationUrls from '../constants/urls'
import transformLoginResponse from '../utils/transformLoginResponse'

const authorizationApi = api.injectEndpoints({
  endpoints: build => ({
    postAccountReset: build.mutation<AccountResetResponseType, AccountPasswordFormType>({
      query: body => ({
        body,
        method: 'POST',
        url: authorizationUrls.reset,
      }),
    }),
    postCheckEmail: build.mutation<ResponseCheckEmailType, CheckEmailType>({
      query: body => ({
        body,
        method: 'POST',
        url: authorizationUrls.checkEmail,
      }),
    }),
    postLogin: build.mutation<UserType, PostLoginPayloadType>({
      invalidatesTags: [TagTypes.POST_LOGIN],
      query: body => ({
        body: body,
        method: 'POST',
        url: authorizationUrls.login,
      }),
      transformResponse: transformLoginResponse,
    }),
    postLoginByCode: build.mutation<UserType, LoginByCodeType>({
      invalidatesTags: [TagTypes.POST_LOGIN],
      query: body => ({
        body: body,
        method: 'POST',
        url: authorizationUrls.loginByCode,
      }),
      transformResponse: transformLoginResponse,
    }),
    postLogout: build.mutation<void, void>({
      invalidatesTags: [TagTypes.POST_LOGOUT],
      query: () => ({
        method: 'POST',
        url: authorizationUrls.logout,
      }),
      transformResponse: () => {
        cleanUserDataStorage()
      },
    }),
    postRegistration: build.mutation<ResponseRegistrationType, RegistrationFormType>({
      query: body => ({
        body,
        method: 'POST',
        url: authorizationUrls.register,
      }),
    }),
  }),
})

export const {
  endpoints: authorizationEndpoints,
  usePostAccountResetMutation,
  usePostCheckEmailMutation,
  usePostLoginByCodeMutation,
  usePostLoginMutation,
  usePostRegistrationMutation,
} = authorizationApi
