import StorageHandler from '../storageHandler'

const userInfoKey = 'user-info'

type UserInfoType = {
  email?: string
}

const userInfoStorage = new StorageHandler<UserInfoType>({ storageKey: userInfoKey, storageType: 'locale' })

export default userInfoStorage
