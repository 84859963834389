import type { CSSProperties } from 'styled-components'

const convertPropertiesToCSS = (properties: CSSProperties): string => {
  return Object.entries(properties)
    .map(([key, value]) => {
      const cssKey = key.replace(/([A-Z])/g, '-$1').toLowerCase()
      if (cssKey === 'font-family' && typeof value === 'string') {
        value = `"${value}"`
      }
      return `${cssKey}: ${value};`
    })
    .join(' ')
}

export default convertPropertiesToCSS
