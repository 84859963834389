import type { CreditCardPayload, PaymentPayloadType } from '@Types/payment/paymentPayload/paymentRequestPayload'
import { PaymentFormFields } from '@constants/payment/paymentFormFields'
import { PaymentPayloadFields } from '@constants/payment/paymentPayloadFields'
import type { PaymentFormType } from '@utils/forms/payment/types'

export default (paymentForm: PaymentFormType): PaymentPayloadType => {
  const result = { ...paymentForm } as unknown

  delete (result as { [PaymentFormFields.CONFIRM_EMAIL]?: string })[PaymentFormFields.CONFIRM_EMAIL]
  delete (result as { [PaymentFormFields.VOUCHER]?: string })[PaymentFormFields.VOUCHER]

  if (paymentForm[PaymentFormFields.CARD_NUMBER]) {
    ;(result as CreditCardPayload)[PaymentFormFields.CARD_NUMBER] = (result as CreditCardPayload)[
      PaymentFormFields.CARD_NUMBER
    ].replaceAll(' ', '')
  }

  if (paymentForm[PaymentFormFields.CARD_EXPIRATION_DATE]) {
    const expirationDate = paymentForm[PaymentFormFields.CARD_EXPIRATION_DATE].split(' / ')

    ;(result as CreditCardPayload)[PaymentPayloadFields.EXPIRATION_MONTH] = expirationDate[0]
    ;(result as CreditCardPayload)[PaymentPayloadFields.EXPIRATION_YEAR] = `${new Date()
      .getFullYear()
      .toString()
      .slice(0, 2)}${expirationDate[1]}`
    delete (result as { [PaymentFormFields.CARD_EXPIRATION_DATE]?: string })[PaymentFormFields.CARD_EXPIRATION_DATE]
  }

  ;(result as PaymentPayloadType)[PaymentFormFields.PAYMENT_METHOD] = paymentForm[PaymentFormFields.PAYMENT_METHOD].id

  if (!(result as PaymentPayloadType)[PaymentPayloadFields.RETURN_URL]) {
    ;(result as PaymentPayloadType)[PaymentPayloadFields.RETURN_URL] = window.location.href
  }

  return result as PaymentPayloadType
}
