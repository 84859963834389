export const loginByCodeFormId = 'login-by-code-form'

export enum LoginByCodeFieldsName {
  Code = 'code',
  Email = 'email',
}

/*export const loginByCodeFieldsPlaceHolders: {
  [LoginByCodeFieldsName.Code]: string
  [LoginByCodeFieldsName.Email]: string
} = {
  [LoginByCodeFieldsName.Code]: 'code',
  [LoginByCodeFieldsName.Email]: 'yourEmail',
}*/

export type LoginByCodeFormType = {
  [LoginByCodeFieldsName.Code]: string[]
  [LoginByCodeFieldsName.Email]: string
}
