import { api } from '@redux/api/api'
import { TagTypes } from '@redux/api/tagTypes'
import type { PostOrderPayloadType } from '@redux/features/order/api'
import type { CancellationPayloadType, CancellationResponseType } from '@redux/features/order/types/cancellation'
import type { IPassengersInfo } from '@redux/features/order/types/passengersInfo'
import type { IAvailableActions, ITrainOrderData } from '@redux/features/order/types/storeOrder'

import type { PassengersFieldsType } from '@Types/common/passengers'
import { type PassengersCategoriesFormType } from '@utils/forms/passengers/types'
import { preparePostPassengersData } from '@utils/passengers/preparePostPassengersPayload'

import orderUrls from '../constants/urls'

const orderApi = api.injectEndpoints({
  endpoints: build => ({
    addOrderPassengers: build.mutation<
      void,
      {
        bookingId: string
        passengersForm: PassengersCategoriesFormType
        requiredField: PassengersFieldsType[]
        type?: 'order_page'
      }
    >({
      invalidatesTags: [TagTypes.POST_PASSENGERS],
      query: ({ bookingId, passengersForm, requiredField, type }) => ({
        body: preparePostPassengersData({ passengersForm, requiredField }),
        method: 'POST',
        url: orderUrls.addPassengers(bookingId, type),
      }),
    }),
    cancelOrder: build.mutation<CancellationResponseType, { bookingId: string; payload: CancellationPayloadType }>({
      invalidatesTags: [TagTypes.GET_ORDER],
      query: ({ bookingId, payload }) => ({
        body: payload,
        method: 'POST',
        url: orderUrls.cancellation(bookingId),
      }),
    }),
    getOrder: build.query<ITrainOrderData, string>({
      keepUnusedDataFor: 0,
      providesTags: [TagTypes.GET_ORDER, TagTypes.POST_PASSENGERS, TagTypes.POST_LOGIN],
      query: bookingId => ({
        url: orderUrls.getOrder(bookingId),
      }),
      transformResponse: (
        response: Omit<ITrainOrderData, 'availableActions'> & {
          availableActions: IAvailableActions[]
        }
      ) => {
        return {
          ...response,
          availableActions: response.availableActions.reduce(
            (acc, action) => ({ ...acc, [action.id]: action }),
            {} as ITrainOrderData['availableActions']
          ),
        }
      },
    }),
    getOrderPassengers: build.query<IPassengersInfo, { bookingId: string; type?: 'order_page' }>({
      providesTags: [TagTypes.POST_PASSENGERS],
      query: ({ bookingId, type }) => ({
        url: orderUrls.getPassengers(bookingId, type),
      }),
    }),
    postOrder: build.mutation<ITrainOrderData, PostOrderPayloadType>({
      query: body => ({
        body,
        method: 'POST',
        url: orderUrls.order,
      }),
    }),
    postpone: build.mutation<void, { bookingId: string }>({
      invalidatesTags: [TagTypes.GET_ORDER],
      query: ({ bookingId }) => ({
        body: {},
        method: 'POST',
        url: orderUrls.postponement(bookingId),
      }),
    }),
  }),
})

export const {
  endpoints: orderEndpoints,
  useAddOrderPassengersMutation,
  useCancelOrderMutation,
  useGetOrderPassengersQuery,
  useGetOrderQuery,
  usePostponeMutation,
} = orderApi
