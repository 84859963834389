import type { FieldData } from 'rc-field-form/es/interface'

import { authorizationEndpoints, fixedPostLoginCacheKey } from '@redux/features/authorization/api'
import type { PostLoginPayloadType } from '@redux/features/authorization/types/login'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { ResponseErrorType } from '@Types/common/responseError'
import getAppConfig from '@services/config/config'
import { LoginFieldsName, type LoginFormType } from '@utils/forms/authorization/login'
import { getVersionFromPath } from '@utils/string/url'

import resetAuthState from '../resetAuthState'

const {
  publicRuntimeConfig: { backendApiUrl },
} = getAppConfig()

export default createAsyncThunk<
  void,
  {
    loginForm: Required<LoginFormType>
    setErrors: (fields: FieldData[]) => void
  },
  { state: RootState }
>('authorization/login', ({ loginForm, setErrors }, { dispatch }) => {
  const loginFormPayload: PostLoginPayloadType = { name: loginForm[LoginFieldsName.Email], ...loginForm }

  void dispatch(authorizationEndpoints.postLogin.initiate(loginFormPayload, { fixedCacheKey: fixedPostLoginCacheKey }))
    .unwrap()
    .then(response => {
      void dispatch(resetAuthState())
      const version = getVersionFromPath(location.pathname)
      version === '2' && window.open(`${backendApiUrl}/user/${response.current_user.uid}`, '_self')
    })
    .catch((error: ResponseErrorType) => {
      setErrors([{ errors: [error.data.message], name: LoginFieldsName.Password }])
    })
})
