import { checkoutEndpoints } from '@redux/features/checkout/api'
import { SimpleModals } from '@redux/features/modals/constants/simpleModals'
import type { IModalsState, INotifications } from '@redux/features/modals/types/modalsState'
import { paymentEndpoints } from '@redux/features/payment/api'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { PaymentStatuses } from '@constants/payment/putPaymentResponse'

const initialState: IModalsState = {
  burgerMainMenu: false,
  drawerModals: {
    [SimpleModals.CANCEL_TICKET]: false,
    [SimpleModals.CART]: false,
    [SimpleModals.FARE_SELECTION]: false,
    [SimpleModals.FARES_LIST]: false,
    [SimpleModals.FILTERS]: false,
    [SimpleModals.MOBILE_DETAILS]: false,
    [SimpleModals.PASSENGERS_FORM]: false,
    [SimpleModals.PAYMENT_FORM]: false,
    [SimpleModals.PREFERENCES]: false,
  },
  notifications: [],
}

export const modalsSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(checkoutEndpoints.postBooking.matchRejected, state => {
      state.drawerModals[SimpleModals.PASSENGERS_FORM] = false
    })
    builder.addMatcher(checkoutEndpoints.postPreBooking.matchFulfilled, state => {
      state.drawerModals[SimpleModals.PASSENGERS_FORM] = false
      state.drawerModals[SimpleModals.PAYMENT_FORM] = true
    })
    builder.addMatcher(
      isAnyOf(paymentEndpoints.putPayment.matchFulfilled, paymentEndpoints.patchPayment.matchFulfilled),
      (state, { payload: paymentData }) => {
        if (paymentData.paymentStatus === PaymentStatuses.FAILED) {
          state.notifications.push({ message: 'paymentFailed', type: 'error' })
        }
      }
    )
    builder.addMatcher(paymentEndpoints.putPayment.matchRejected, (state, response) => {
      const { message, propertyPath } = (response.payload?.data || {}) as { message?: string; propertyPath?: string }

      state.notifications.push({
        description: `${propertyPath ? `${propertyPath}: ` : ''}${message ? message : ''}`,
        duration: 10,
        message: 'paymentFailed',
        type: 'error',
      })
    })
  },
  initialState,
  name: 'modals',
  reducers: {
    clearNotification: state => {
      state.notifications = initialState.notifications
    },
    closeAllModals: state => {
      state.drawerModals = initialState.drawerModals
    },
    setNotification: (state, action: PayloadAction<INotifications>) => {
      state.notifications.push(action.payload)
    },
    setSimpleModalValue: (state, action: PayloadAction<{ name: SimpleModals; value: boolean }>) => {
      state.drawerModals[action.payload.name] = action.payload.value
    },
    setTildaPageId: (state, action: PayloadAction<IModalsState['tildaPageId']>) => {
      state.tildaPageId = action.payload
    },
    toggleBurgerMainMenu: (state, action: PayloadAction<boolean>) => {
      state.burgerMainMenu = action.payload
    },
  },
})

export const {
  clearNotification,
  closeAllModals,
  setNotification,
  setSimpleModalValue,
  setTildaPageId,
  toggleBurgerMainMenu,
} = modalsSlice.actions

export default modalsSlice.reducer
