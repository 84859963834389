import {
  saveEmail,
  setCurrentStep,
  setIsCheckedEmail,
  setIsOpenAuthorizationPopup,
} from '@redux/features/authorization/authorization.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { AuthorizationStep } from '@components/authorization/constant/steps'

export default createAsyncThunk<
  void,
  {
    additionalAction?: () => void
  } | void,
  { state: RootState }
>('authorization/resetAuthState', (props, { dispatch }) => {
  dispatch(saveEmail(''))
  dispatch(setIsOpenAuthorizationPopup(false))
  dispatch(setCurrentStep(AuthorizationStep.LOGIN))
  dispatch(setIsCheckedEmail(false))
  props?.additionalAction?.()
})
