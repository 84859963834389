import { urlVersionPrefixV2, urlVersionPrefixV3 } from '@constants/api/api'

export default {
  // /api/v2/order/{bookingId}/{type}/passenger
  addPassengers: (bookingId: string, type = 'order_page') =>
    `${urlVersionPrefixV2}order/${bookingId}/${type}/passenger`,

  // /api/v3/internal/train/order/{bookingId}/cancellation
  cancellation: function (bookingId: string) {
    return `${this.order}/${bookingId}/cancellation`
  },

  // /api/v3/internal/train/order/{bookingId}
  getOrder: function (bookingId: string) {
    return `${this.order}/${bookingId}`
  },

  // /api/v2/order/{bookingId}/{type}/passengers/info
  getPassengers: (bookingId: string, type = 'order_page') =>
    `${urlVersionPrefixV2}order/${bookingId}/${type}/passengers/info`,

  // /api/v3/internal/train/order
  order: `${urlVersionPrefixV3}internal/train/order`,

  // /api/v3/internal/train/order/{bookingId}/postponement
  postponement: function (bookingId: string) {
    return `${this.order}/${bookingId}/postponement`
  },
}
