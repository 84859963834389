import { colorsList } from '@themes/colors/colorsList'

export const lightThemeShadows = {
  big: '0 0 200px #0000000D', //5%
  button: '0 0 50px #0000001A', //10%
  card: '0 2px 20px 0 #0000004D', //30%
  cardClasses: '0 1px 8px 0 #0000004D', //30%
  cardRoute: '0 10px 60px 0 #0000001A', //10%
  collapseHead: '0 28px 90px 0 #0000000D', //5%
  medium: '0 0 100px #0000001A', //10%
  mediumL: '0 0 150px #00000026', //15%
  mobileCoachClassCard: '0 2px 10px 0 #0000004D', //30%
  modal: '0 0 200px #0000001A', //10%
  popover: '0 6px 15px -2px #1e1e1e1A', //10%
  small: '0 0 20px #00000026', //15%
  text: '0 2px 4px #00000080', //50%
  xSmall: '0 0 10px #00000026', //15%
}

export const darkThemeShadows: typeof lightThemeShadows = {
  big: `0 4px 100px 20px ${colorsList.shadow}66`,
  button: `0 0 50px 0 ${colorsList.shadow}26`,
  card: '0 2px 20px 0 #0000004D', //30%
  cardClasses: '0 1px 8px 0 #0000004D', //30%
  cardRoute: '0 10px 60px 0 #0000001A', //10%
  collapseHead: '0 28px 90px 0 #00000040', //25%
  medium: '0 0 100px #0000001A', //10%
  mediumL: '0 0 150px #00000026', //15%
  mobileCoachClassCard: '0 2px 10px 0 #0000004D', //30%
  modal: `0 4px 100px 20px ${colorsList.shadow}33`,
  popover: '0 6px 15px -2px #1e1e1e1A', //10%
  small: `0 0 20px 0 ${colorsList.shadow}26`,
  text: '0 2px 4px #00000080', //50%
  xSmall: `0 0 10px ${colorsList.shadow}26`,
}

export const railNinjaLightThemeShadows: typeof lightThemeShadows = {
  big: '0 0 200px #0000000D', //5%
  button: 'none', //10%
  card: '0 2px 20px 0 #0000004D', //30%
  cardClasses: '0 1px 8px 0 #0000004D', //30%
  cardRoute: '0 10px 60px 0 #0000001A', //10%
  collapseHead: '0 28px 90px 0 #0000000D', //5%
  medium: '0 0 100px #0000001A', //10%
  mediumL: '0 0 150px #00000026', //15%
  mobileCoachClassCard: '0 2px 10px 0 #0000004D', //30%
  modal: '0 0 200px #0000001A', //10%
  popover: '0 6px 15px -2px #1e1e1e1A', //10%
  small: '0 0 20px #00000026', //15%
  text: '0 2px 4px #00000080', //50%
  xSmall: '0 0 10px #00000026',
}

export const railNinjaDarkThemeShadows: typeof lightThemeShadows = {
  big: '0 4px 100px 20px #07090F66',
  button: 'none',
  card: '0 2px 20px 0 #0000004D', //30%
  cardClasses: '0 1px 8px 0 #0000004D', //30%
  cardRoute: '0 10px 60px 0 #0000001A', //10%
  collapseHead: '0 28px 90px 0 #0000000D', //5%
  medium: '0 0 100px #0000001A', //10%
  mediumL: '0 0 150px #00000026', //15%
  mobileCoachClassCard: '0 2px 10px 0 #0000004D', //30%
  modal: '0 4px 100px 20px #07090F33',
  popover: '0 6px 15px -2px #1e1e1e1A', //10%
  small: '0 0 20px 0 #07090F26',
  text: '0 2px 4px #00000080', //50%
  xSmall: '0 0 10px #00000026',
}
