import type { FieldData } from 'rc-field-form/es/interface'

import {
  setCurrentStep,
  setIsCheckedEmail,
  setIsOpenAuthorizationPopup,
} from '@redux/features/authorization/authorization.slice'
import { setNotification } from '@redux/features/modals/modals.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { ResponseErrorType } from '@Types/common/responseError'
import { AuthorizationStep } from '@components/authorization/constant/steps'
import type { LoginFormType } from '@utils/forms/authorization/login'
import { LoginFieldsName } from '@utils/forms/authorization/login'
import { AccountResetType } from '@utils/forms/authorization/resetPassword'
import { getVersionFromPath } from '@utils/string/url'

import { authorizationEndpoints, fixedPostLoginCacheKey } from '../../api'
import resetPassword from '../resetPassword'

export default createAsyncThunk<
  void,
  {
    isExtended?: boolean
    loginForm: LoginFormType
    setErrors?: (fields: FieldData[]) => void
  },
  { state: RootState }
>('authorization/checkEmail', ({ isExtended, loginForm, setErrors }, { dispatch }) => {
  void dispatch(
    authorizationEndpoints.postCheckEmail.initiate(
      { email: loginForm.email || '' },
      { fixedCacheKey: fixedPostLoginCacheKey }
    )
  )
    .unwrap()
    .then(response => {
      const { message, need_login_link, success } = response

      if (success && !need_login_link) {
        if (isExtended) {
          dispatch(setCurrentStep(AuthorizationStep.LOGIN_EXTENDED))
          dispatch(setIsOpenAuthorizationPopup(true))
        }
        dispatch(setIsCheckedEmail(true))
      } else if (success && need_login_link) {
        const version = getVersionFromPath(location.pathname)
        void dispatch(
          resetPassword({
            isExtended,
            loginForm,
            resetType: version === '2' ? AccountResetType.LoginEmail : AccountResetType.LoginCode,
          })
        )
      } else {
        dispatch(
          setNotification({
            description: message,
            message: 'somethingWrong',
            type: 'error',
          })
        )
      }
    })
    .catch((error: ResponseErrorType) => {
      if (error.status === 404) {
        setErrors && setErrors([{ errors: [error.data.message], name: LoginFieldsName.Email }])
      } else if (error.status === 422) {
        setErrors && setErrors([{ errors: [error.data.message], name: LoginFieldsName.Email }])
      } else {
        dispatch(
          setNotification({
            description: error.data?.message,
            message: 'somethingWrong',
            type: 'error',
          })
        )
      }
    })
})
