import type { UserRoles } from '@constants/user/userRoles'

import StorageHandler from '../storageHandler'

const authKey = 'auth'

type AuthType = {
  roles?: UserRoles[]
  temp?: {
    userId?: number
    ['X-Auth-Token']?: string
  }
  userId?: number
  ['X-Auth-Token']?: string
}

const authStorage = new StorageHandler<AuthType>({ storageKey: authKey, storageType: 'locale' })

export default authStorage
