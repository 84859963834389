import { PaymentMethodsName } from '@constants/payment'
import {
  appleGooglePayRequiredFields,
  basicRequiredFields,
  creditCardRequiredFields,
} from '@constants/payment/requiredFields'

const getPaymentMethodsRequiredFields = (paymentMethod: PaymentMethodsName) => {
  switch (paymentMethod) {
    case PaymentMethodsName.PAYPAL:
    case PaymentMethodsName.GOOGLE_PAY:
    case PaymentMethodsName.APPLE_PAY:
      return appleGooglePayRequiredFields

    case PaymentMethodsName.CREDIT_CARD:
      return creditCardRequiredFields

    default:
      return basicRequiredFields
  }
}

export default getPaymentMethodsRequiredFields
