import { api } from '@redux/api/api'

import type { IStation } from '@Types/stations/station'
import { urlVersionPrefix, urlVersionPrefixV2 } from '@constants/api/api'
import type { LocalesList } from '@constants/locales/localesList'
import getLocaleForRequests from '@utils/locale/getLocaleForRequests'

const trainDataApi = api.injectEndpoints({
  endpoints: build => ({
    getCarrier: build.query<ICarrierShort, number>({
      keepUnusedDataFor: 900,
      query: carrierId => ({
        url: urlVersionPrefix + `carrier/${carrierId}`,
      }),
    }),
    getMedia: build.query<ISlideShow, number>({
      keepUnusedDataFor: 900,
      query: mediaId => ({
        url: urlVersionPrefixV2 + `media/${mediaId}`,
      }),
    }),
    getStation: build.query<IStation, { locale?: LocalesList; stationId: number | string }>({
      keepUnusedDataFor: 900,
      query: ({ locale, stationId }) => ({
        url: `${getLocaleForRequests(locale)}${urlVersionPrefix}station/${stationId}`,
      }),
    }),
  }),
})

export const {
  endpoints: { getCarrier, getMedia, getStation },
  useGetCarrierQuery,
  useGetMediaQuery,
  useGetStationQuery,
} = trainDataApi
