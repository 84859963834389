import {
  additionalBaseUrl,
  externalApiPrefix,
  externalApiPrefixV2,
  urlVersionPrefixV2,
  urlVersionPrefixV3,
} from '@constants/api/api'
import type { PaymentMethodsName } from '@constants/payment'

export default {
  // /api/v3/internal/train/booking/{hash}
  booking: (hash: string) => `${urlVersionPrefixV3}internal/train/booking/${hash}`,

  // /api/v1/external-booking/{bookingId}/currency-update
  bookingCurrency: (bookingId: string) => `${externalApiPrefix}/${bookingId}/currency-update`,

  // /api/v1/external-booking/{bookingId}/order-item/info
  bookingOrderItemInfo: (bookingId: string) => `${externalApiPrefix}/${bookingId}/order-item/info`,

  // /api/v2/external-booking/{bookingId}/paid-service/{stepId}/info
  bookingPaidServiceInfo: (bookingId: string, stepId: string) =>
    `${externalApiPrefixV2}/${bookingId}/paid-service/${stepId}/info`,

  // /api/v2/external-booking/{bookingId}/service
  bookingService: (bookingId: string) => `${externalApiPrefixV2}/${bookingId}/service`,

  // /api/v2/booking/{bookingId}/total-price/{paymentMethod}
  bookingTotalPrice: (bookingId: string, paymentMethod: PaymentMethodsName) =>
    `${urlVersionPrefixV2}booking/${bookingId}/total-price/${paymentMethod}`,

  // /api/v1/external-booking
  externalBooking: externalApiPrefix,

  // /api/v1/external-booking/{bookingId} USELESS RESPONSE
  //getExternalBooking: (bookingId: string) => `${externalApiPrefix}/${bookingId}`,

  // /api/v1/external-booking/{bookingId}/passenger
  passengers: (bookingId: string) => `${externalApiPrefix}/${bookingId}/passenger`,

  // /api/v1/external-booking/{bookingId}/prebooking
  preBooking: (bookingId: string) => `${externalApiPrefix}/${bookingId}/prebooking`,

  // /api/v2/timetable
  search: `${additionalBaseUrl}${urlVersionPrefixV2}timetable`,

  // /api/v2/timetable/batch
  searchBatch: `${additionalBaseUrl}${urlVersionPrefixV2}timetable/batch`,

  // /api/v2/timetable/coach-class
  timetableCoachClasses: `${additionalBaseUrl}${urlVersionPrefixV2}timetable/coach-class`,

  // /api/v2/booking/{bookingId}/voucher
  voucher: (bookingId: string) => `${urlVersionPrefixV2}booking/${bookingId}/voucher`,
}
