import { css } from 'styled-components'

export default css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${p => p.theme.colors.primary};
    width: 4px;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-track {
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 2px;
    background: ${p => p.theme.colors.backgroundMain};
  }
`
