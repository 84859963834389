import { authorizationEndpoints } from '@redux/features/authorization/api'
import {
  setCurrentStep,
  setIsLoadingAuth,
  setIsOpenAuthorizationPopup,
} from '@redux/features/authorization/authorization.slice'
import { setNotification } from '@redux/features/modals/modals.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { ResponseErrorType } from '@Types/common/responseError'
import { AuthorizationStep } from '@components/authorization/constant/steps'
import type { LoginFormType } from '@utils/forms/authorization/login'
import { AccountResetType } from '@utils/forms/authorization/resetPassword'

export default createAsyncThunk<
  void,
  { isExtended?: boolean; loginForm: LoginFormType; resetType: AccountResetType },
  { state: RootState }
>('authorization/resetPassword', ({ isExtended, loginForm, resetType }, { dispatch }) => {
  dispatch(setIsLoadingAuth(true))
  void dispatch(
    authorizationEndpoints.postAccountReset.initiate({
      email: loginForm.email || '',
      reset_type: resetType,
    })
  )
    .unwrap()
    .then(response => {
      if (response.success) {
        if (resetType === AccountResetType.LoginEmail) {
          dispatch(setCurrentStep(AuthorizationStep.CHECK_EMAIL_NOTIFICATION))
          return
        }
        if (isExtended) {
          dispatch(setCurrentStep(AuthorizationStep.LOGIN_BY_CODE_EXTENDED))
          dispatch(setIsOpenAuthorizationPopup(true))
        } else {
          dispatch(setCurrentStep(AuthorizationStep.LOGIN_BY_CODE))
        }
      }
    })
    .catch((error: ResponseErrorType) => {
      dispatch(
        setNotification({
          description: error.data?.message,
          message: 'somethingWrong',
          type: 'error',
        })
      )
    })
    .finally(() => {
      dispatch(setIsLoadingAuth(false))
    })
})
