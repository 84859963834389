import dayjs from 'dayjs'

dayjs.parseZone = function (date?: dayjs.ConfigType) {
  if (typeof date === 'string') {
    const match = date.match(/^(.*)[+-](\d{2}):(\d{2})|(Z)$/)
    if (match !== null) {
      if (match[0] === 'Z') {
        return dayjs(date, {
          // eslint-disable-next-line prefer-rest-params
          args: arguments,
          utc: true,
        } as dayjs.OptionType)
      } else if (match[0][0] === '+') {
        const hour = parseInt(match[2])
        const minute = parseInt(match[3])

        return dayjs(match[1], {
          $offset: hour * 60 + minute,
          // eslint-disable-next-line prefer-rest-params
          args: arguments,
        } as dayjs.OptionType)
      } else {
        const hour = parseInt(match[2])
        const minute = parseInt(match[3])

        return dayjs(match[1], {
          $offset: hour * -60 + minute,
          // eslint-disable-next-line prefer-rest-params
          args: arguments,
        } as dayjs.OptionType)
      }
    }
  }

  return dayjs(date, {
    // eslint-disable-next-line prefer-rest-params
    args: arguments,
  } as dayjs.OptionType)
}
