import type { IRoute } from '@Types/routes/route'

export const getFirstTrainData = (trains: IRoute['trains']) => {
  const trainsObjectEntries = Object.entries(trains)
  const firstTrainWithCoachClasses =
    trainsObjectEntries.find(([, train]) => train.coach_classes.length) ||
    trainsObjectEntries.find(([, train]) => train.cheapest_coach_class)

  if (!firstTrainWithCoachClasses) return

  const [firstTrainKey, firstTrain] = firstTrainWithCoachClasses
  const coachClass = firstTrain.coach_classes[1] || firstTrain.coach_classes[0] || firstTrain.cheapest_coach_class

  return {
    coachClass,
    fare: coachClass.cheapest_fare || coachClass.fares[0],
    train: firstTrain,
    trainKey: firstTrainKey,
  }
}
