import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

type Props = {
  action: (locale: string) => void
  needInitExecute?: boolean
}

export default ({ action, needInitExecute }: Props) => {
  const locale = useRouter().locale
  const prevLocale = useRef<string | undefined>(needInitExecute ? undefined : locale)

  useEffect(() => {
    if (locale && prevLocale.current !== locale) {
      prevLocale.current = locale
      action(locale)
    }
  }, [action, locale])
}
