import { sizes } from '@themes/sizes'

export const mediaQueries = {
  desktop: `@media all and (max-width: ${sizes.contentMaxWidth}px)`,
  desktopM: `@media all and (max-width: ${sizes.desktopM}px)`,
  desktopS: `@media all and (max-width: ${sizes.desktopS}px)`,
  desktopSAndMore: `@media all and (min-width: ${sizes.desktopS}px)`,
  desktopXS: `@media all and (max-width: ${sizes.desktopMinWidth}px)`,
  mobile: `@media all and (max-width: ${sizes.mobileMaxWidth}px)`,
  mobileL: `@media all and (max-width: ${sizes.mobileL}px)`,
  mobileM: `@media all and (max-width: ${sizes.mobileM}px)`,
  mobileS: `@media all and (max-width: ${sizes.mobileS}px)`,
  mobileTablet: `@media all and (max-width: ${sizes.tabletMaxWidth}px)`,
  noMobile: `@media all and (min-width: ${sizes.tabletMinWidth}px)`,
  onlyDesktop: `@media all and (min-width: ${sizes.desktopMinWidth}px)`,
  onlyDesktopS: `@media all and (min-width: ${sizes.desktopMinWidth}px) and (max-width: ${sizes.desktopS}px)`,
  tablet: `@media all and (min-width: ${sizes.tabletMinWidth}px) and (max-width: ${sizes.tabletMaxWidth}px)`,
  tabletDesktopS: `@media all and (min-width: ${sizes.tabletMinWidth}px) and (max-width: ${sizes.desktopS}px)`,
  tabletMMobileS: `@media all and (min-width: ${sizes.mobileS + 1}px) and (max-width: ${sizes.tabletM}px)`,
}
