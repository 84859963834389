import type { FieldData } from 'rc-field-form/es/interface'

import { authorizationEndpoints } from '@redux/features/authorization/api'
import { setCurrentStep } from '@redux/features/authorization/authorization.slice'
import { setNotification } from '@redux/features/modals/modals.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { ResponseErrorType } from '@Types/common/responseError'
import { AuthorizationStep } from '@components/authorization/constant/steps'
import type { AccountPasswordFormType } from '@utils/forms/authorization/resetPassword'
import { ResetAccountFieldsName } from '@utils/forms/authorization/resetPassword'

export default createAsyncThunk<
  void,
  {
    data: AccountPasswordFormType
    setErrors: (fields: FieldData[]) => void
  },
  { state: RootState }
>('authorization/resetAccount', ({ data, setErrors }, { dispatch }) => {
  void dispatch(authorizationEndpoints.postAccountReset.initiate(data))
    .unwrap()
    .then(() => {
      dispatch(setCurrentStep(AuthorizationStep.CHECK_EMAIL_NOTIFICATION))
    })
    .catch((error: ResponseErrorType) => {
      if (error.status === 404) {
        setErrors([{ errors: [error.data.message], name: ResetAccountFieldsName.Email }])
      } else {
        dispatch(
          setNotification({
            description: error.data?.message,
            message: 'somethingWrong',
            type: 'error',
          })
        )
      }
    })
})
