import { authorizationEndpoints } from '@redux/features/authorization/api'
import { setNotification } from '@redux/features/modals/modals.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

export default createAsyncThunk<void, boolean | void, { state: RootState }>(
  'authorization/logout',
  (withNotification = true, { dispatch }) => {
    void dispatch(authorizationEndpoints.postLogout.initiate()).then(() => {
      withNotification &&
        dispatch(
          setNotification({
            message: 'logoutNotification',
            type: 'info',
          })
        )
    })
  }
)
