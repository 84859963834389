import { TrainFilters } from '@redux/features/trainFilters/constants/trainFilters.constants'

import type { IRoute } from '@Types/routes/route'

export default (trains: IRoute['trains'], name: TrainFilters.ARRIVAL_STATION | TrainFilters.DEPARTURE_STATION) => {
  return new Set(
    Object.values(trains).map(train => {
      return name === TrainFilters.ARRIVAL_STATION
        ? train.arrival_station.single_name
        : train.departure_station.single_name
    })
  )
}
