import { api } from '@redux/api/api'
import type { BannerBlockInfoType } from '@redux/features/homePage/types/bannerBlockInfo'
import type { ContextBlocksType } from '@redux/features/routesPage/types/contextBlocksType'
import type { LayoutBuilderType } from '@redux/features/routesPage/types/layoutBuilder'
import type { IGetBodeIdResponse } from '@redux/features/routesPage/types/nodeId'

import type { ExtendBlocksType } from '@Types/blocks/blocks'
import type { NodeBlock } from '@Types/blocks/nodeBlock'
import type { CustomContentId } from '@constants/blockList/customContentBlockList'
import { regExpressions } from '@utils/string/regularExpressions'

import routePageUrls from '../constants/urls'

export const fixedGetNodeIdCacheKey = 'getNodeId'

const routesPageApi = api.injectEndpoints({
  endpoints: build => ({
    getBlockById: build.query<ExtendBlocksType, string>({
      keepUnusedDataFor: 0,
      query: blockId => ({
        keepUnusedDataFor: 0,
        url: routePageUrls.getBlockById(blockId),
      }),
    }),
    getContextBlock: build.query<
      ContextBlocksType,
      { contentId: CustomContentId; directionStation?: number; nodeId: number | string }
    >({
      query: ({ contentId, directionStation, nodeId }) => ({
        url: routePageUrls.getContextBlock(nodeId, contentId, directionStation),
      }),
    }),
    getLayoutBuilder: build.query<LayoutBuilderType | NodeBlock, { nodeId: number | string; sectionId?: string }>({
      query: ({ nodeId, sectionId }) => ({
        url: routePageUrls.getLayoutBuilder(nodeId, sectionId),
      }),
    }),
    getNodeId: build.mutation<IGetBodeIdResponse | null, string | undefined>({
      query: pathName => ({
        body: { path: (pathName || document.location.pathname).replace(regExpressions.removeVersionFromPath, '') },
        method: 'POST',
        url: routePageUrls.getNodeId,
      }),
      transformResponse: (response: IGetBodeIdResponse, _, arg) =>
        response?.systemPath === (arg || document.location.pathname).replace(regExpressions.removeVersionFromPath, '')
          ? null
          : response,
    }),
    getRouteInfo: build.query<BannerBlockInfoType, number | string>({
      query: nodeId => ({
        url: routePageUrls.getRouteInfo(nodeId),
      }),
    }),
  }),
})

export const {
  endpoints: routesPageEndpoints,
  useGetBlockByIdQuery,
  useGetContextBlockQuery,
  useGetLayoutBuilderQuery,
  useGetNodeIdMutation,
} = routesPageApi

export const { getBlockById, getContextBlock, getLayoutBuilder, getNodeId } = routesPageEndpoints
