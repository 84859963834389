import type { PaymentActions } from '@constants/payment/putPaymentResponse'

import StorageHandler from '../storageHandler'

const authKey = 'payment'

type AuthType = {
  lastActions: Record<string, PaymentActions>
  paymentAttempt: Record<string, boolean>
}

const paymentStorage = new StorageHandler<AuthType>({ storageKey: authKey, storageType: 'session' })

export default paymentStorage
