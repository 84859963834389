export enum SimpleModals {
  CANCEL_TICKET = 'CANCEL_TICKET',
  CART = 'CART',
  FARE_SELECTION = 'FARE_SELECTION',
  FARES_LIST = 'FARES_LIST',
  FILTERS = 'FILTERS',
  MOBILE_DETAILS = 'MOBILE_DETAILS',
  PASSENGERS_FORM = 'PASSENGERS_FORM',
  PAYMENT_FORM = 'PAYMENT_FORM',
  PREFERENCES = 'PREFERENCES',
}
