import { urlVersionPrefix, urlVersionPrefixV2 } from '@constants/api/api'
import type { CustomContentId } from '@constants/blockList/customContentBlockList'

export default {
  // /api/v1/block/uuid/{blockId}
  getBlockById: (blockId: string) => `${urlVersionPrefix}block/uuid/${blockId}`,

  // /api/v1/node/{nodeId}/context-block/{contentId}
  // /api/v1/node/{nodeId}/context-block/{contentId}?departure_station={directionStation}
  getContextBlock: (nodeId: number | string, contentId: CustomContentId, directionStation?: number) =>
    `${urlVersionPrefix}node/${nodeId}/context-block/${contentId}${
      directionStation ? `?departure_station=${directionStation}` : ''
    }`,

  // /api/v1/entity/node/{nodeId}/layout-builder
  // /api/v1/entity/node/{nodeId}/layout-builder/section/{sectionId}
  getLayoutBuilder: (nodeId: number | string, sectionId?: number | string) =>
    `${urlVersionPrefix}entity/node/${nodeId}/layout-builder${sectionId ? `/section/${sectionId}` : ''}`,

  // /api/v2/path-lookup
  getNodeId: `${urlVersionPrefixV2}path-lookup`,

  // /api/v1/route-info/{nodeId}
  getRouteInfo: (nodeId: number | string) => `${urlVersionPrefix}route-info/${nodeId}`,
}
