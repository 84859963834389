import { PaymentFormFields } from '@constants/payment/paymentFormFields'
import type { PaymentFormSavedType, PaymentFormType } from '@utils/forms/payment/types'

const notSavedPaymentFields: (keyof PaymentFormType)[] = [
  PaymentFormFields.CARD_NUMBER,
  PaymentFormFields.CARD_EXPIRATION_DATE,
  PaymentFormFields.CARD_TYPE,
  PaymentFormFields.NAME_ON_CARD,
  PaymentFormFields.SECRET_CODE,
  PaymentFormFields.TERMS_AND_CONDITION,
]

const getFormValuesForRedux = (paymentFormType: PaymentFormType) => {
  const result: PaymentFormSavedType = { ...paymentFormType }

  notSavedPaymentFields.forEach(fieldName => {
    if (fieldName in result) delete (result as PaymentFormType)[fieldName]
  })

  return result
}

export default getFormValuesForRedux
